import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "clearButton"]

    connect() {
        this.onQueryChange();
    }

    clear() {
        const element = this.inputTarget;
        element.value = "";
        this.clearButtonTarget.style.display = "none";
        this.inputTarget.focus();
    }

    onQueryChange() {
        if (!this.inputTarget.value || this.inputTarget.value.length === 0) {
            this.clearButtonTarget.style.display = "none";
        } else {
            this.clearButtonTarget.style.display = "flex";
        }
    }
}