// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>


import {Turbo} from "@hotwired/turbo-rails"
import '~/entrypoints/application.tailwind.css'
import {registerControllers} from 'stimulus-vite-helpers'
import {Application} from '@hotwired/stimulus'


// Stimulus setup
const application = Application.start()
// Configure Stimulus development experience
// application.debug = false
// window.Stimulus   = application
const controllers = import.meta.glob('../**/*_controller.js', {eager: true})
registerControllers(application, controllers)

// Turbo
Turbo.start()