import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["iconOpen", "iconClose", "mobileMenu", "content"]
    static values = { visible: {type: Boolean, default: false} }


    connect() {
        this.updateMenuState();
    }

    toggleMenu() {
        this.visibleValue = !this.visibleValue
        this.updateMenuState();
    }

    updateMenuState() {
        if (this.visibleValue) {
            this.setVisibility(this.iconOpenTarget, false)
            this.setVisibility(this.iconCloseTarget, true)
            this.setVisibility(this.mobileMenuTarget, true)
            this.setVisibility(this.contentTarget, false)
        } else {
            this.setVisibility(this.iconOpenTarget, true)
            this.setVisibility(this.iconCloseTarget, false)
            this.setVisibility(this.mobileMenuTarget, false)
            this.setVisibility(this.contentTarget, true)
        }
    }

    setVisibility(element, visible) {
        if (visible) {
            element.classList.add("block")
            element.classList.remove("hidden")
        } else {
            element.classList.add("hidden")
            element.classList.remove("block")
        }
    }
}